/* eslint-disable prettier/prettier */
// import node module libraries
import { useState, Fragment } from 'react';
import { Card, Image, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { BsEye } from 'react-icons/bs';
import Url from 'components/Url';
// import tippy tooltip
import Tippy from '@tippyjs/react';
import 'tippy.js/animations/scale.css';
import {getTimePassed} from "../../helper/utils.js";

const JobListingListviewCard = (props) => {
  const { item } = props;
  const url1 = Url();
  const url = url1['url'];
  const s3 = url1["s3"];
  const location = item.location
  const llocation = location.split(',')
  // console.log('Location');
  // console.log(location);

  const [s3url, setS3url] = useState(s3 + "imgupload/");

  return (
    <Card className="card-bordered mb-4 card-hover cursor-pointer">
      <Card.Body>
        <div>
          <div className="d-md-flex">
            <div className="mb-3 mb-md-0">
              <Image
                src={s3url + item.org_logo}
                height="50" width="50"
                alt="Geeks UI - Bootstrap 5 Template"
                className="icon-shape border rounded-circle"
              />
            </div>
            <div className="ms-md-3 w-100 mt-3 mt-xl-1">
              <div className="d-flex justify-content-between mb-5">
                <div>
                  <h3 className="mb-1 fs-4">
                    {item.internship_details}
                  </h3>
                  <div>
                    <span>at {item.org_name} </span>
                    {/* <span className="text-dark ms-2 fw-medium">
                      {item.rating}{' '}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        className="bi bi-star-fill text-warning align-baseline"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                    </span> */}
                    {/* <span className="ms-0">({item.totalReviews} Reviews)</span> */}
                  </div>
                </div>
                  {item.activity_rank > 0 && 
                  <Fragment>
                    <div style={{"color":"var(--geeks-primary)"}} className="ml-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-lightning-fill" viewBox="0 0 16 16">
                        <path d="M5.52.359A.5.5 0 0 1 6 0h4a.5.5 0 0 1 .474.658L8.694 6H12.5a.5.5 0 0 1 .395.807l-7 9a.5.5 0 0 1-.873-.454L6.823 9.5H3.5a.5.5 0 0 1-.48-.641l2.5-8.5z"></path>
                      </svg>
                      <span>Actively Hiring</span>
                    </div>
                    
                  </Fragment>
                  }                 
                {/* <div>
                  bookmark
                  <Tippy content="Add to Bookmarks" animation={'scale'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-bookmark text-muted bookmark"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z" />
                    </svg>
                  </Tippy>
                </div> */}
              </div>
              <div className="d-md-flex justify-content-between ">
                <div className="mb-2 mb-md-0">
                  {/* <span className="me-2">
                    <i className="fe fe-briefcase text-muted"></i>
                    <span className="ms-1 ">{item.experience}</span>
                  </span> */}
                  <span className="me-2">
                    ₹
                    {item.compensation1 != null ?
                      <span className="ms-1 ">{item.compensation1}{item.compensation2 === "Lump Sum" ? " " + item.compensation2 : item.compensation2 == "Months" ? "/Month" : "/Week"} stipend</span>
                      : <span className="ms-1 ">
                        Unpaid
                      </span>
                    }
                  </span>
                  <span className="me-2">
                    <i className="fe fe-map-pin text-muted"></i>
                    {llocation.length > 5 ?
                      <span className="ms-1 ">{`${llocation.slice(0, 5)} , `}</span>
                      :
                      llocation.map((loc, index) =>
                        <span className="ms-1 " key={index}>{`${loc} , `}</span>)
                    }
                    {/* <span className="ms-1">{item.location}</span> */}
                  </span>
                </div>
                <span className="ps-1">
										<i className="fe fe-clock text-muted"></i>
										<span> Posted at:{getTimePassed(item.posting_date)}</span>
									</span>
              </div>
							<Col xs={12} className="mt-2 d-flex justify-content-end">
								{item.impressions > 0 && 
								<Fragment>
									<span style={{"color":"var(--geeks-primary)"}} className="ml-auto">
										<BsEye size={13} />
										<span className="small ms-1">{item.impressions} impressions</span>
									</span>
									
								</Fragment>
								}	
								</Col>	              
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default JobListingListviewCard;
