// import node module libraries
import React, { Fragment, useState, useMemo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Modal,
  Row,
  Col,
  Badge,
  Dropdown,
  Table,
  Toast,
  Button
} from "react-bootstrap";

import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
} from "react-table";
import Select from "react-select";

// import bootstrap / feather icons
import {
  FileEarmarkText,
  Trash3,
  FileArrowUp,
  XCircle,
  Calendar2Week,
  Files,
  Star,
  CheckCircle,
  BoxArrowRight,
  Chat,
} from "react-bootstrap-icons";
import { MoreVertical } from "react-feather";

// import custom components
// import RightIconInfoCard from "./common/cards/RightIconInfoCard";
import DashboardIconBox from "./DashboardIconBox";
import GlobalFilter from "./common/advance-table/GlobalFilter";

import Pagination from "./common/advance-table/Pagination";
import RightIconInfoCard from "./common/cards/RightIconInfoCard";

// import data files
import ChatApp from "./chat/Chat";
import Url from "components/Url";
import CheckEmployerProfileStatus from "./CheckEmployerProfileStatus";
import { ToastContainer, toast } from "react-toastify";
import SelectColumnFilter from "./common/advance-table/SelectFilter";
import { dateBetweenFilterFn, DateRangeColumnFilter } from "./common/advance-table/DateFilter";

const Dashboard = () => {
  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const url1 = Url();
  const url = url1["url"];
  const id = window.localStorage.getItem("id");
  const emptoken = window.localStorage.getItem("emptoken")
  const [topCardsData, setTopCardsData] = useState();
  const [name, setName] = useState("");
  const [internshipList, setInternshipList] = useState();
  const [load, setLoad] = useState(false)
  const [newChatMessage, setNewChatMessage] = useState(false);
	const [newChatCounter, setNewChatCounter] = useState(0);
  const [employerProfile,setEmployerProfile] = useState([]);
  const [showIncompleteProfile, setShowIncompleteProfile] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);

  const dummyData = [
    {
        id: 1,
        internship_details: 'Campus Ambassodor',
        created_at: '13 Feb, 2023',
        internship_status: 'Inactive',
        received: 'View hired candicreated_ats',
        badge: 'danger'
    }
  ]

  const getNewMsgCounter = () => {
    const api1 = url + 'getNewChatCounterForEmployer/' + id + '/E' + emptoken;
		fetch(api1, {
			method: 'GET',
		})
			.then((apidata1) => {
				//console.log(apidata1)
				return apidata1.json();
			})
			.then((actualdata1) => {
				//console.log(actualdata1);
				//console.log(actualdata1.newChatMessageCount.totalUnreadCount);
				if (actualdata1.newChatMessageCount.totalUnreadCount > 0) {
					if (actualdata1.newChatMessageCount.totalUnreadCount) {
						//console.log('setting true');
						setNewChatCounter(actualdata1.newChatMessageCount.totalUnreadCount)
						setNewChatMessage(true);
					} else {
						setNewChatCounter(0);
						setNewChatMessage(false);
					}
				}else{
          if(actualdata1.message){
            if(actualdata1.message){
              if( actualdata1.message == "unauthorized"){
                localStorage.clear();
                sessionStorage.setItem("showTab","employer"); 
                window.location.href = "/login";
                //toast.warning("Session expired, please login again",{onClose: () => {}});
              }
          }
          }
        }
			});
  }

  useEffect(() => {
    sessionStorage.removeItem("empProfile");//remove this variable in each render so that it will be verified on each login
    if (!id) {
      window.location.href = "/student-login";
      return;
    }
    getNewMsgCounter()
    const api = url + "getEmployerDashboardStats/" + id;
    //Umesh - 20220906  -End
    fetch(api, {
      method: "GET",
    })
      .then((apidata1) => {
        return apidata1.json();
      })
      .then((actualdata1) => {
        //console.log(actualdata1);
        setName(actualdata1.fullname);
        setTopCardsData([
          {
            id: 1,
            icon: <FileEarmarkText />,
            text: actualdata1.intership_post,
            subText: "Posted",
            color: "secondary",
            link: "#",
            bg: "rgba(254,233,217, 0.8)",
          },
          {
            id: 2,
            icon: <Files />,
            text: actualdata1.applied,
            subText: "Received",
            color: "success",
            link: "#",
            bg: "rgba(215,240,229, 0.8)",
          },
          {
            id: 3,
            icon: <Star />,
            text: actualdata1.shortlist,
            subText: "Shortlisted",
            color: "warning",
            link: "#",
            bg: "rgba(255,238,204, 0.8)",
          },
          {
            id: 4,
            icon: <CheckCircle />,
            text: actualdata1.offersent,
            subText: "Hired",
            color: "info",
            link: "#",
            bg: "rgba(204,226,233, 0.8)",
          },
          {
            id: 5,
            icon: <BoxArrowRight />,
            text: actualdata1.rejected,
            subText: "Rejected",
            color: "danger",
            link: "#",
            bg: "rgba(242,211,211, 0.8)",
          },
          {
            id: 6,
            icon: <Chat />,
            text: "Chat",
            subText: newChatCounter+" Messages",
            color: "primary",
            link: "#",
            modal: "#chatModal",
            bg: "rgba(213,215,234, 0.8)",
          },
        ]);

        const api2 = url + "getEmployerInternshipByEmployerId/" + id;
        fetch(api2, {
          method: "GET",
        })
          .then((apidata1) => {
            return apidata1.json();
          })
          .then((actualdata1) => {
            setInternshipList(actualdata1);
          });
      });
      setLoad(false)
      localStorage.setItem("new", "0")
     
      //Umesh - 20220906  -End
      fetch(url + "getEmployerProfileById/" + id, {
        method: "GET",
      })
        .then((profileJson) => {
          return profileJson.json();
        })
        .then((profileData) => {

          setEmployerProfile(profileData);

        });
  }, [load, newChatCounter]);


  const inactive = (pid) => {
		const response = window.confirm(
			'Internship will be set Inactive, Are you sure?'
		);
		if (response == true) {
			const api3 = url + 'deActivateEmployerInternship/' + pid;

			fetch(api3, {
				method: 'GET',
			})
				.then((apidata1) => {
					return apidata1.json();
				})
				.then((actualdata1) => {
					if (
						actualdata1['message'] === 'employer_internship Record Disabled'
					) {
				
						toast.success('Internship Inactive....')
            setLoad(true)
					} else {
						toast.error('Error....!');
					}
					//console.log(actualdata1)
				});
		} else {
			return;
		}
	};

	const similarint = (id) => {
		const api = url + 'copyInternship';
		fetch(api, {
			method: 'POST',
			body: JSON.stringify({ internshipId: id }),
			headers: { 'Content-Type': 'application/json' },
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				window.location.href = '/empdashboard/post-internship/'+actualdata1.intshipid;
        localStorage.setItem("new", "0");
			});
	};

  const extendInternshipDeadline = (id, extendFromCurrentDateVar = false) => {
		//By default the internship deadline is extended using the date stored in the database. But for inactive internships
		//we want to extend the date from the current date as we are activating the internship today. Hence
		//we are passing the additional paramtere to the API
		const api = url + 'extendInternshipDeadline';
		fetch(api, {
			method: 'POST',
			body: JSON.stringify({
				internshipId: id,
				extendFromCurrentDate: extendFromCurrentDateVar,
			}),
			headers: { 'Content-Type': 'application/json' },
		})
			.then((apidata1) => {
				return apidata1.json();
			})
			.then((actualdata1) => {
				toast.success('Internship deadline extended by a month')
        setLoad(true)
			});
	};

  const del = (pid) => {
		const response = window.confirm(
			'Internship will be deleted, Are you sure?'
		);
		if (response === true) {
			const api2 = url + 'deleteEmployerInternshipById/' + pid;

			fetch(api2, {
				method: 'DELETE',
			})
				.then((apidata1) => {
					return apidata1.json();
				})
				.then((actualdata1) => {
					if (actualdata1['message'] === 'employer_internship Record deleted') {
						toast.success('Deleted successfully...');
            setLoad(true)
					} else {
						toast.error('Error....!');
					}
					//console.log(actualdata1)
				});
		} else {
			return;
		}
	};

  // The forwardRef is important!!
  // Dropdown needs access to the DOM node in order to position the Menu
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Link
      to=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="btn-icon btn btn-ghost btn-sm"
    >
      {children}
    </Link>
  ));

  const ActionMenu = (props) => {
    const { status, intID, startDate } = props;
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle}>
          <MoreVertical size="15px" className="text-gray" />
        </Dropdown.Toggle>
        <Dropdown.Menu align={"end"}>
          {status === "Draft" && (
            <Fragment>
              <Dropdown.Item eventKey="1" onClick={() => {
                localStorage.setItem("new", "0")
                  window.location.href="/empdashboard/post-internship/"+intID
                  }}>
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                View Internship
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => del(intID)}>
                <Trash3 size={15} className="dropdown-item-icon" /> Delete
              </Dropdown.Item>
            </Fragment>
          )}
          {(status === "Inactive") && (
            <Fragment>
              <Dropdown.Item eventKey="1" onClick={() => {
                localStorage.setItem("new", "0")
                  window.location.href="/empdashboard/post-internship/"+intID
                  }}>
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                View Internship
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => similarint(intID)}>
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                Post Similar
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => extendInternshipDeadline(intID, true)}>
                <div className="d-flex ">
                  <div>
                    <Calendar2Week size={15} className="dropdown-item-icon" />
                  </div>
                  <div className="ms-2">
                    <div> Extend deadline by Month</div>
                    <small className="text-muted">
                      (Expires on {startDate})
                    </small>
                  </div>
                </div>
              </Dropdown.Item>
            </Fragment>
          )}
          {status === "Active" && (
            <Fragment>
              <Dropdown.Item eventKey="1" onClick={() => {
                localStorage.setItem("new", "0")
                  window.location.href="/empdashboard/post-internship/"+intID
                  }}>
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                View Internship
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => inactive(intID)}>
                <XCircle size={15} className="dropdown-item-icon" /> Deactivate
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => extendInternshipDeadline(intID)}>
                <div className="d-flex ">
                  <div>
                    <Calendar2Week size={15} className="dropdown-item-icon" />
                  </div>
                  <div className="ms-2">
                    <div> Extend deadline by Month</div>
                    <small className="text-muted">
                    (Expires on {startDate})
                    </small>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item eventKey="1" onClick={() => similarint(intID)}>
                <FileEarmarkText size={15} className="dropdown-item-icon" />{" "}
                Post Similar
              </Dropdown.Item>
              <Dropdown.Item eventKey="3" onClick={() => del(intID)}>
                <Trash3 size={15} className="dropdown-item-icon" /> Delete
              </Dropdown.Item>
            </Fragment>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  // Column heading and cell format setup
  const columns = useMemo(
    () => [
      { accessor: "id", Header: "ID", disableFilters: true
      },
      { accessor: "internship_details", Header: "Internship Name",disableFilters: true },
      { accessor: "created_at", Header: "Date",
      Filter: DateRangeColumnFilter,
        filter: dateBetweenFilterFn
      },
      {
        accessor: "internship_status",
        Header: "Status",
        Filter: SelectColumnFilter,
        filter: 'includes',
        Cell: ({ value, row }) => {
          return (
            <Badge
              bg={
                value === "Draft"
                  ? "info"
                  : value === "Active"
                  ? "success"
                  : value === "Inactive"
                  ? "danger"
                  : null
              }
              className="text-white"
            >
              {value}{" "}
            </Badge>
          );
        },
      },
      {
        accessor: "received",
        Header: "Received",
        disableFilters: true,
        Cell: ({ row }) => {
          return (
            <Link
              to="/empdashboard/applications/applied"
              //to="/empdashboard/applications/internshipgate-certified-interns"
              className={`btn btn-primary btn-sm`}
              onClick={() => {
                window.localStorage.setItem("showVIStudentMessage",true)
                window.localStorage.setItem("intId", row.original.id);
                window.localStorage.setItem("intDetails", JSON.stringify({
                  name: row.original.internship_details,
                  location: row.original.location,
                  stipend: `${row.original.compensation1}/${row.original.compensation2}`,
                  startDate: row.original.startdate,
                  duration: row.original.duration,
                  internship_status: row.original.internship_status,
                }));
              }}
            >
              View Applications
            </Link>
          );
        },
      },
      {
        accessor: "action",
        Header: "",
        disableFilters: true,
        Cell: ({ row }) => {
          return <ActionMenu intID={row.original.id} status={row.original.internship_status} startDate={row.original.startdate} />;
        },
      },
    ],
    [statusFilter]
  );
  const data = useMemo(
    () => internshipList || dummyData,
    [internshipList]
  );
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
      <input
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
      />
    )
  }
  

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  )
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    nextPage,
    previousPage,
    state,
    gotoPage,
    pageCount,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 10,
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
          else return false;
        }),
      },
      defaultColumn
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );
  
  const { pageIndex, globalFilter } = state;

  return (
    <>
    <ToastContainer />
    <Fragment>
      {/* Welcome Section */}
      <CheckEmployerProfileStatus />
      <section className="pt-5 pb-6 bg-white">
        <Container>
          <Row className="border-bottom pb-5 g-0 align-items-center">
            <Col md={9}>
              <h1 className="mb-md-0">
                Hi, Welcome back! <span className="text-secondary">{name}</span>
              </h1>
            </Col>
            <Col md={3} className="text-md-end">
              <Link onClick={() => localStorage.setItem("new", "1")} to={"/empdashboard/post-internship"} className="btn btn-secondary">
                Post Internship
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Information cards section */}
      <section className="pb-8 bg-white">
        <Container>
          <Row className="row-cols-xl-6 row-cols-md-3 g-3">
            {topCardsData?.slice(0, 5).map((item, index) => {
              return (
                <Col className="col" key={index}>
                  <Link to={item.link} className="text-reset">
                    <DashboardIconBox id={id} item={item} />
                  </Link>
                </Col>
              );
            })}
            
            <Col className="col">
              <Link to="#" className="text-reset" onClick={handleShow}>
                {topCardsData ? (
                  <RightIconInfoCard
                    item={topCardsData[topCardsData.length - 1]}
                  />
                ) : null}
              </Link>
            </Col>
           
          </Row>
        </Container>
      </section>

      {/* Internship Data List */}
      <section className="pb-14 bg-white">
        <Container>
          <Row className="align-items-center">
            <Col md={8}>
              <div>
                <h2 className="mb-md-0 mb-3">Internship List </h2>
              </div>
            </Col>
            <Col md={4}>
              <div>
                <GlobalFilter
                  filter={globalFilter}
                  setFilter={setGlobalFilter}
                  placeholder="Search internship post"
                />
              </div>
            </Col>
          </Row>
          <Row className="mt-6">
            <Col lg={12}>
              <Table
                hover
                responsive
                {...getTableProps()}
                className="text-nowrap table-centered"
                style={{minHeight:"100px"}}
              >
                <thead className="table-light">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}> 
                          <div style={{"height": "20px"}}>
                          {column.render("Header")}
                          </div>
                          <div className="mt-2" style={{"height": "40px"}}>
                            {column.canFilter ? column.render('Filter') : null}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {page.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>

              {/* Pagination @ Footer */}
              {internshipList?.length > 10
              &&
              <Pagination
                previousPage={previousPage}
                pageCount={pageCount}
                pageIndex={pageIndex}
                gotoPage={gotoPage}
                nextPage={nextPage}
              />
              }
            </Col>
          </Row>
        </Container>
      </section>

      {/* Chat Modal Popup  */}
      <Modal backdrop="static" show={show} onHide={handleClose} scrollable size="xl">
        <Modal.Header closeButton>
          <Modal.Title id="chatModalLabel">
            Messages from all internships & jobs
          </Modal.Title>
        </Modal.Header>
        <ChatApp internshipList={internshipList} />
      </Modal>
    </Fragment>
    </>
  );
};

export default Dashboard;
