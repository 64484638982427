// import node module libraries
import { Card, Image, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, Fragment } from "react";
import likeclicked from "../../assets/images/job/likeclicked.svg";
import like from "../../assets/images/job/liked.svg";
import { BsEye } from "react-icons/bs";
// import tippy tooltip
import Tippy from "@tippyjs/react";
import "tippy.js/animations/scale.css";
import Url from "components/Url";
const JobListingListviewCard = ({
  item,
  fav,
  setFav,
  Favorite,
  Apply,
  getTimePassed,
}) => {
  const url1 = Url();
  const s3 = url1["s3"];
  const url = url1["url"];
  const [s3url, setS3url] = useState(s3 + "imgupload/");

  return (
    <Card className="card-bordered mb-4 card-hover">
      <Card.Body>
        <div>
          <div className="d-md-flex">
            <div className="mb-3 mb-md-0 col-1">
              <Image
                src={s3url + item.org_logo}
                alt="Geeks UI - Bootstrap 5 Template"
                className="icon-shape border rounded-circle"
                style={{
                  height: "56px",
                  width: "56px",
                }}
              />
            </div>
            <div
              className="ms-md-3  mt-3 mt-xl-1 col-11"
              style={{ overflowWrap: "anywhere" }}
            >
              <div
                className="d-flex justify-content-between mb-5 "
                style={{ marginRight: "25px" }}
              >
                <div>
                  <h3 className="mb-1 fs-4">
                    <Link
                      to={`/internship-details/${item.id}`}
                      className="text-inherit me-1"
                    >
                      {item.internship_details}
                    </Link>
                  </h3>
                  <div>
                    <span>at {item.org_name} </span>
                  </div>
                </div>
                <div>
                  {fav.has(item.id) ? (
                    <img
                      src={likeclicked}
                      alt=" "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        Favorite(item.id);
                      }}
                    />
                  ) : (
                    <img
                      src={like}
                      alt=" "
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        Favorite(item.id);
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="d-md-flex justify-content-between ">
                <div className="mb-2 mb-md-0 ">
                  <span className="mt-1">
                    <i className="fe fe-briefcase text-muted"></i>
                    <span className="ms-1 text-truncate">
                      Duration:
                      {item.duration1 +
                        (item.duration2 == "M" ? " Months" : " Weeks")}
                    </span>
                  </span>
                  <span className="mt-1 mx-2">
                    <span className="text-muted" style={{ padding: "3px" }}>
                      &#8377;
                    </span>
                    <span className="ms-1 text-truncate">
                      Stipend:
                      {item.compensation1
                        ? item.compensation1 + " per month"
                        : "Not disclosed"}
                    </span>
                  </span>
                  <span className="me-2  mx-2">
                    <i className="fe fe-map-pin text-muted"></i>
                    <span className="ms-1 ">Location:{item.location}</span>
                  </span>
                  <span className="mt-1 mx-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-tools"
                      viewBox="0 0 16 16"
                    >
                      <path d="M1 0 0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.27 3.27a.997.997 0 0 0 1.414 0l1.586-1.586a.997.997 0 0 0 0-1.414l-3.27-3.27a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0Zm9.646 10.646a.5.5 0 0 1 .708 0l2.914 2.915a.5.5 0 0 1-.707.707l-2.915-2.914a.5.5 0 0 1 0-.708ZM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11Z" />
                    </svg>
                    <span className="ms-1 ">Skills:{item.skill}</span>
                  </span>

                  <span className="ps-1">
                    <i className="fe fe-clock text-muted"></i>
                    <span> Posted at:{getTimePassed(item.posting_date)}</span>
                  </span>
                  <span className="mt-1 mx-2">
                    <i className="fe fe-clock text-muted"></i>
                    <span> Starts in:Start Immediately</span>
                  </span>
                </div>
              </div>
              <Row className="mt-1">
                <Col xs={12} className="mb-1 d-flex justify-content-end">
                  {item.impressions > 0 && (
                    <Fragment>
                      <span
                        style={{ color: "var(--geeks-primary)" }}
                        className="ml-auto"
                      >
                        <BsEye size={13} />
                        <span className="small ms-1">
                          {item.impressions} impressions
                        </span>
                      </span>
                    </Fragment>
                  )}
                </Col>
                <Col xs={12} className="mt-2 d-flex justify-content-end">
                  <Link
                    to={`/internship-details/${item.id}`}
                    className="text-inherit me-1"
                  >
                    <button
                      type="button"
                      className="btn  btn-sm btn-outline-warning"
                      style={{ marginRight: "10px" }}
                    >
                      More details
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={() => Apply(item.id)}
                  >
                    Apply Now
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default JobListingListviewCard;
