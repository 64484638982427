// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
} from "react-table";
import { Link } from "react-router-dom";
import {
  Col,
  Row,
  Button,
  Image,
  Dropdown,
  Table,
  Container,
  Modal,
  Form,
} from "react-bootstrap";
import Axios from "axios";
import { PencilSquare, Trash } from "react-bootstrap-icons";
import { BsPlayCircle } from "react-icons/bs";

import { ToastContainer, toast } from "react-toastify";

// import custom components
import GlobalFilter from "components/elements/advance-table/GlobalFilter";
import Pagination from "components/elements/advance-table/Pagination";
import DotBadge from "components/elements/bootstrap/DotBadge";
import Url from "components/Url";
import GKYouTube from "../GKYouTube";
import YouTube from "react-youtube";
import Select from "react-select";

const AssessmentQuiz = (props) => {
  const { courseQuizList, setLoad, courseId } = props;
  const admtoken = localStorage.getItem("admtoken");
  const url1 = Url();
  const s3 = url1.s3;
  const url = url1.url;

  const [showModal, setShowModal] = useState(false);

  const [formData, setFormData] = useState([]);

  const handleEdit = (itemId, index) => {
    if (itemId) setFormData(courseQuizList[index]);
      else setFormData({ subject_id: courseId });

    setShowModal(true);
  };

  const handleDelete = (e, itemId, index) => {
    e.preventDefault();
    const res = window.confirm("The question will be deleted, are you sure?");
    if (!res) {
      return;
    }

    let apiURL = url + "deleteCourseTestQuestionId";

    try {
      Axios.delete(apiURL, {
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${admtoken}`,
        },
        data: {
          id: itemId,
        },
      }).then(
        (response) => {
          if (response.status === 202) {
            toast.success(response.data.message, { autoClose: 1500 });
            setLoad(true);
          } else {
            toast.warning(response.data.message, { autoClose: 1500 });
          }
        },
        (error) => {
          toast.warning(response.data.message, { autoClose: 1500 });
        }
      );
    } catch (error) {
      if (error.response) {
        // The request was made, but the server responded with an error
        toast.warning(error.response, { autoClose: 1500 });
      } else if (error.request) {
        // The request was made, but no response was received
        toast.warning("No response received.", { autoClose: 1500 });
      } else {
        // Something happened in setting up the request that triggered an error
        toast.warning("Error:" + error.message, { autoClose: 1500 });
      }
    }
  };

  return (
    <Container>
      <div className="mt-3">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Question</th>
              <th>Option 1</th>
              <th>Option 2</th>
              <th>Option 3</th>
              <th>Option 4</th>
              <th>Correct Option</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {courseQuizList &&
              courseQuizList.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.question}</td>
                  <td>{item.op1}</td>
                  <td>{item.op2}</td>
                  <td>{item.op3}</td>
                  <td>{item.op4}</td>
                  <td>{item.correct_option}</td>
                  <td>
                    <Col className="d-flex">
                      <span
                        onClick={() => handleEdit(item.id, index)}
                        style={{ cursor: "pointer" }}
                      >
                        <PencilSquare />
                      </span>
                      <span
                        onClick={(e) => handleDelete(e, item.id, index)}
                        className="ms-3"
                        style={{ cursor: "pointer" }}
                      >
                        <Trash />
                      </span>
                    </Col>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <Button
          variant="primary"
          onClick={() => handleEdit(null, null)}
          size="sm"
        >
          Add New Question
        </Button>
      </div>
      {showModal && (
        <FormPopup
          formData={formData}
          showModal={showModal}
          setShowModal={setShowModal}
          setLoad={setLoad}
          courseId={courseId}
        />
      )}
    </Container>
  );
};

/**********************************************************************************************/
/************************* Popup Modal Component *************************************/
/**********************************************************************************************/

const FormPopup = (props) => {
  const { formData, setShowModal, showModal, setLoad } = props;

  const url1 = Url();
  const url = url1["url"];

  //const Detail = [];

  const [isEdit, setIsEdit] = useState(false);
  const [createValidate, setCreateValidate] = useState(false);

  const [show, setShow] = useState(props.show);

  const [id, setId] = useState();
  const [courseId, setCourseId] = useState();
  const [question, setQuestion] = useState();
  const [op1, setOp1] = useState();
  const [op2, setOp2] = useState();
  const [op3, setOp3] = useState();
  const [op4, setOp4] = useState();
  const [correctOption, setCorrectOption] = useState();
  const [mark, setMark] = useState();
  const [level, setLevel] = useState();
  const [activeInd, setActiveInd] = useState();

  const [apiMessage, setApiMessage] = useState("");
  const [apiStatus, setApiStatus] = useState(0);

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const admtoken = localStorage.getItem("admtoken");
  const correctOptionValues = [{label:1, value:1}, {label:2,value:2},{label:3,value:3},{label:4,value:4}];

  useEffect(() => {

    setCourseId(formData.subject_id);
    if (formData.id) {
      setId(formData.id);
      setQuestion(formData.question);
      setOp1(formData.op1);
      setOp2(formData.op2);
      setOp3(formData.op3);
      setOp4(formData.op4);
      setCorrectOption(getById(formData.correct_option));
    }
    setActiveInd(formData.active_ind);
  }, []);

  const getById = (id) => {
    return correctOptionValues.find(item => item.value == id);
  };

  const handleCorrectOptionChange = (e) => {
    setCorrectOption(e)  
  };

  const handleClose = () => {
    setShowModal(false);
    setLoad(true);
  };

  const saveRecord = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      e.stopPropagation();
      setCreateValidate(true);
      return;
    }

    // const creapi = url + "saveCourseVideoById";
    // fetch(creapi, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     id:id,
    //     course_id:courseId,
    //     topic_name:topicName,
    //     video_link:videoLink,
    //     video_id:videoId,
    //   }),
    //   headers: { "Content-Type": "application/json" },
    // })
    //   .then((apidata) => {
    //     return apidata.json();
    //   })
    //   .then((actualdata) => {
    //     setApiMessage(actualdata["message"]);
    //     setApiStatus(actualdata["status"]);

    //     if (!id && actualdata["status"] == 1) {
    //       setButtonDisabled(true);
    //     }
    //   });

    let apiURL = url + "saveCourseTestQuestion";

    try {
      Axios.post(
        apiURL,
        {
          id: id,
          courseId:courseId,
          question: question,
          op1: op1,
          op2: op2,
          op3: op3,
          op4: op4,
          correct_option: correctOption.value
        },
        {
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${admtoken}`,
          },
        }
      ).then(
        (response) => {
          if (response.status === 200) {
            setApiMessage(response.data.message);
            setApiStatus(1);
            if(!id) setButtonDisabled(true);
            setLoad(true);
          } else {
            setApiMessage(response.data.message);
            setApiStatus(2);
          }
        },
        (error) => {
          setApiMessage(response.data.message);
          setApiStatus(2);
        }
      );
    } catch (error) {
      console.log(error.response);

      if (error.response) {
        // The request was made, but the server responded with an error
        handleErrorResponse(error.response);
      } else if (error.request) {
        // The request was made, but no response was received
        console.error("No response received.");
      } else {
        // Something happened in setting up the request that triggered an error
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{isEdit ? "Edit Question" : "Add Question"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row><Col><h4>{id ? `Question No. ${id}` : "New Question"}</h4></Col></Row>
        <Form noValidate onSubmit={saveRecord}>
          <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Question</h5>
            </div>
            <div className="col-10">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Question"
                  id="question"
                  name="question"
                  value={question}
                  onChange={(e) =>
                    setQuestion(e.target.value ? e.target.value : "")
                  }
                  required
                  isValid={createValidate && question}
                  isInvalid={createValidate && !question}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Question
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Option 1</h5>
            </div>
            <div className="col-10">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Option 1"
                  id="op1"
                  name="op1"
                  value={op1}
                  onChange={(e) => setOp1(e.target.value ? e.target.value : "")}
                  required
                  isValid={createValidate && op1}
                  isInvalid={createValidate && !op1}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Option 1
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Option 2</h5>
            </div>
            <div className="col-10">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Option 2"
                  id="op2"
                  name="op2"
                  value={op2}
                  onChange={(e) => setOp2(e.target.value ? e.target.value : "")}
                  required
                  isValid={createValidate && op2}
                  isInvalid={createValidate && !op2}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Option 2
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Option 3</h5>
            </div>
            <div className="col-10">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Option 3"
                  id="op3"
                  name="op3"
                  value={op3}
                  onChange={(e) => setOp3(e.target.value ? e.target.value : "")}
                  required
                  isValid={createValidate && op3}
                  isInvalid={createValidate && !op3}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Option 3
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Option 4</h5>
            </div>
            <div className="col-10">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Option 4"
                  id="op4"
                  name="op4"
                  value={op4}
                  onChange={(e) => setOp4(e.target.value ? e.target.value : "")}
                  required
                  isValid={createValidate && op4}
                  isInvalid={createValidate && !op4}
                />
                <Form.Control.Feedback type="invalid">
                  Please enter Option 4
                </Form.Control.Feedback>
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2">
            <div className="col-2 text-end ">
              <h5 className="m-1">Correct Option</h5>
            </div>
            <div className="col-10">
              <Form.Group className="mb-3" controlId="correctOption">
                {/* <Form.Label>Industry Type</Form.Label> */}
                <Select
                  options={correctOptionValues}
                  onChange={handleCorrectOptionChange}
                  value={correctOption}
                  required
                />
              </Form.Group>
            </div>
          </Row>
          <Row className="mb-2 d-flex d-inline">
            <div className="mb-3 d-flex justify-content-end">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                disabled={buttonDisabled}
              >
                Save
              </button>

              <button
                type="button"
                className="btn btn-outline-warning btn-sm ms-2"
                onClick={handleClose}
              >
                Close
              </button>
            </div>
          </Row>
          <Row>
            {apiMessage !== "" && (
              <span className={apiStatus ? "text-success " : "text-danger "}>
                {apiMessage}
              </span>
            )}
            {/*apiStatus==1 && <span className="text-success small">Education details updated successfully</span> */}
          </Row>{" "}
        </Form>
      </Modal.Body>
    </Modal>
  );
};
export default AssessmentQuiz;
