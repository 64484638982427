import React, { useEffect, useRef, useState, createRef } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
  Font,
  Svg,
  Path,
  Circle,
  Polyline,
  Rect,
  Line,
} from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import {
  useA4Scale,
  TemplatePage,
  renderHTMLContent,
  getDisplayDate,
} from "../../utils";
import { Row, Col } from "react-bootstrap";
import DummyProfilePic from "../../../../../assets/images/background/profilepic.png";
import { FiPhone } from "react-icons/fi";

Font.registerHyphenationCallback((word) => {
  // Return entire word as unique part
  return [word];
});


const styles = StyleSheet.create({
  page: { 
  display: "flex",
  fontFamily: "Roboto",
  padding: "30px",
   },

  circle: {
    position: "absolute",
    marginLeft: "-15px",
    marginTop: "4px",
  },
 
  jobTitle: {
    fontFamily: "Roboto",
    fontSize: "11.5px",
    marginBottam: "45px",
    paddingLeft: "120px",
    color: "#666666",
    fontWeight: 500,
    width:"300px",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  header: {
    marginBottom: "50px",
  },
 
  right: {
    width: "450px",
    //backgroundColor: "#43479e", 
  },
  left: {
    width: "250px",
    //padding:"10px 30px 30px 50px",

  },
  contact: {
    fontSize: "12px",
    color: "#666666",
    fontWeight: 400,
    marginLeft: "45px",
  },
  profileImageWrapper: {
    padding: "3px",
    width: "115px",
    height: "120px",
    marginLeft: "-2px",
    top:"-4px",
  },
  profileImage: {
    width: "115px",
    height: "120px",
    marginLeft: "-2px",
    borderRadius: "10%",
    border:"2px solid blue",
    top:"-4px",
  },
});

//aditya start
const ContactInfo = ({ data: { Phone, Country, display_email } }) => (
  <View style={styles.contact}>
    <Text
      style={{
        marginTop: "5px",
        paddingLeft:"78px",
        fontSize:"11px",
        fontFamily: "Roboto",
      }}
    >{display_email}
    </Text>
    <Text
      style={{
        fontFamily: "Roboto",
        marginTop: "18px",
        paddingLeft:"80px",
      }}
    >
      {Phone}
    </Text>
  
    <Text></Text>
  </View>
);
    
const RenderView = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, startDate, endDate, description, city, } = fieldMap;

  if (!items || !items.length) {
    return <View />;
  }
const {
  template_color_code,
}=data;

  return (
    <View
      style={{
       // color: "#000000",
        marginBottom: "30px",
        width:"250px",
        
      }}
    >
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          marginLeft: "-10px",
          
        }}
      >
        {heading == "Work Experience" ? (
          <View
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "-3px",
              backgroundColor:template_color_code,
              width: "20px",
              height: "22px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <Svg
              // stroke="#fffffff"
              fill="#fffffff"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="12px"
              width="12px"
              xmlns="http://www.w3.org/2000/svg"
            >
             
                <Path fill="none" d="M0 0H24V24H0z"></Path>
                <Path d="M15 3c.552 0 1 .448 1 1v2h5c.552 0 1 .448 1 1v13c0 .552-.448 1-1 1H3c-.552 0-1-.448-1-1V7c0-.552.448-1 1-1h5V4c0-.552.448-1 1-1h6zM8 8H6v11h2V8zm10 0h-2v11h2V8zm-4-3h-4v1h4V5z"></Path>
            </Svg>
          </View>
        ) : null}
        {heading == "EDUCATION" ? (
          <View
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "-3px",
              backgroundColor:template_color_code,
              width: "22px",
              height: "22px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <Svg
              stroke="#fffffff"
              fill="#fffffff"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="12px"
              width="12px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path d="M5 13.18v4L12 21l7-3.82v-4L12 17l-7-3.82zM12 3L1 9l11 6 9-4.91V17h2V9L12 3z"></Path>
            </Svg>
          </View>
        ) : null}
        {heading == "EXTRA_CURRICULAR_ACTIVITY" ? (
          <View
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "-3px",
              backgroundColor:template_color_code,
              width: "22px",
              height: "22px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <Svg
              // stroke="#fffffff"
              fill="#fffffff"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="12px"
              width="12px"
              xmlns="http://www.w3.org/2000/svg"
            >
                <Path fill="none" d="M0 0h24v24H0z"></Path>
                <Path d="M4 2h16a1 1 0 0 1 1 1v19.276a.5.5 0 0 1-.704.457L12 19.03l-8.296 3.702A.5.5 0 0 1 3 22.276V3a1 1 0 0 1 1-1zm8 11.5l2.939 1.545-.561-3.272 2.377-2.318-3.286-.478L12 6l-1.47 2.977-3.285.478 2.377 2.318-.56 3.272L12 13.5z"></Path>
            </Svg>
          </View>
        ) : null}
        
        {heading == "COURSES" ? (
          <View
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "-5px",
              backgroundColor:template_color_code,
              width: "22px",
              height: "22px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
             
            }}
          >
            <Svg
              stroke="#fffffff"
              fill="#fffffff"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="12px"
              width="12px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path d="M4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm16-4H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-1 9H9V9h10v2zm-4 4H9v-2h6v2zm4-8H9V5h10v2z"></Path>
            </Svg>
          </View>
        ) : null}
        {heading == "INTERNSHIPS" ? (
          <View
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "-3px",
              backgroundColor:template_color_code,
              width: "22px",
              height: "22px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
            }}
          >
            <Svg
              stroke="#fffffff"
              fill="#fffffff"
              stroke-width="0"
              viewBox="0 0 15 15"
              height="12px"
              width="12px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <Path
                fill-rule="evenodd"
                d="M0 12.5A1.5 1.5 0 001.5 14h13a1.5 1.5 0 001.5-1.5V6.85L8.129 8.947a.5.5 0 01-.258 0L0 6.85v5.65z"
                clip-rule="evenodd"
              ></Path>
              <Path
                fill-rule="evenodd"
                d="M0 4.5A1.5 1.5 0 011.5 3h13A1.5 1.5 0 0116 4.5v1.384l-7.614 2.03a1.5 1.5 0 01-.772 0L0 5.884V4.5zm5-2A1.5 1.5 0 016.5 1h3A1.5 1.5 0 0111 2.5V3h-1v-.5a.5.5 0 00-.5-.5h-3a.5.5 0 00-.5.5V3H5v-.5z"
                clip-rule="evenodd"
              ></Path>
            </Svg>
          </View>
        ) : null}
        <Text
          style={{
            fontSize: "14px",
            fontWeight: 700,
            color:template_color_code,
            marginLeft: "13px",
          }}
        >
          {heading}
        </Text>
      </View>
      {items.map((item) => (
        <View style={{ marginBottom: "15px" }}>
          <View
            style={{
              fontSize: "13px",
              fontWeight: "500",
              marginTop: "10px",
            }}
          >
            <Svg
              xmlns="http://www.w3.org/2000/svg"
              height="10px"
              width="10px"
              stroke="#43479e"
              fill="#43479e"
              viewBox="0 0 24 24"
              style={styles.circle}
            >
            </Svg>
            <Text>{item[title]}</Text>
          </View>
          <Text style={{ fontSize: "11px", marginTop: "6px", fontWeight: 300 }}>
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "10px",
              color: "#646464",
              marginTop: "3px",
              marginBottom: "4px",
            }}
          >
            {getDisplayDate(item[startDate], item[endDate])}
            {item[city] ? ` ,  ${item[city]}` : ""}
          </Text>
          {item[description] && (
            <View style={{ marginTop: "4px" }}>
              {renderHTMLContent(item[description], {
                "*": { color: "#000000", fontWeight: 300, fontSize: "10px",textAlign:"justify", },
              })}
            </View>
          )}
        </View>
      ))}
    </View>
  );
};

const CustomSection = ({ data, dataKey, heading, fieldMap = {} }) =>{
  const items=data[dataKey];
  console.log("data key", data[dataKey]);
  const {title, city, startDate, endDate, description,custom_title }=fieldMap;
    if(!items||!items.length){
       return <View/>;
    }
    const {
      template_color_code,
    }=data;
    return (
      <View
        style={{
          marginBottom: "15px",
          marginLeft: "15px",
          paddingTop:"5px",
        }}
      >
      <Svg
      xmlns="http://www.w3.org/2000/svg"
      height="30px"
      width="25px"
      viewBox="0 0 24 24"
      style={{
        position: "absolute",
        marginLeft: "-25px",
        marginTop: "-3px",
      }}
    >
      <Rect x="3" y="2" width="18" height="20" rx="2" ry="2" style={{ fill: "#f0f0f0", stroke: template_color_code, strokeWidth: 1,paddingLeft:"10px"}} />
      <Line x1="7" y1="8" x2="17" y2="8" style={{ stroke: "#000", strokeWidth: 1 }} />
      <Line x1="7" y1="12" x2="17" y2="12" style={{ stroke: "#000", strokeWidth: 1 }} />
      <Line x1="7" y1="16" x2="13" y2="16" style={{ stroke: "#000", strokeWidth: 1 }} />
      <Path d="M 16 19 L 20 15" style={{ stroke: "#000", strokeWidth: 1 }} />
      <Path d="M 20 15 L 18 13 L 14 17 L 16 19" style={{ fill: template_color_code, stroke: "#000", strokeWidth: 1 }} />
      <Path d="M 18 13 L 17 12 L 19 10 L 20 11" style={{ fill: template_color_code, stroke: "#000", strokeWidth: 1 }} />
    </Svg>
    

        <Text
          style={{
            fontSize: "16px",
            fontWeight: 600,
             color:"#000000",
            
            
          }}
        >
          {heading}
        </Text>
        {items.map((item) => (
          
          <View style={{ marginBottom: "8px" }} wrap={true}>
            
            <View
              style={{
                fontSize: "16px",
                fontWeight: "900",
                color:template_color_code,
                paddingLeft:"10px",
              }}
            >
              <Text>{item[custom_title]}</Text>
              
            </View>



            <View
              style={{
                fontSize: "13px",
                fontWeight: "500",
                marginTop: "10px",
              }}
            >
              <Text>{item[title]}</Text>
              
            </View>
          
            <Text
              style={{
                fontSize: "10px",
                color: "#363434",
                marginTop: "3px",
                marginBottom: "4px",
              }}
            >
              {getDisplayDate(item[startDate], item[endDate])}
  
              {item[city] ? ` ,  ${item[city]}` : ""}
              
            </Text>

            {item[description] && (
              <View
                style={{
                  marginTop: "4px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
              {console.log(dataKey, item[description])}

                {renderHTMLContent(item[description], {
                  "*": {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "12px",
                    paddingRight: "5px",
                    alignItems: "baseline",
                    position: "sticky",
                    textAlign: "justify",
                  },
                })}
              </View>
            )}


          </View>
        ))}
      </View>
    );
};
{/*
const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View style={{ padding: "15px 30px" }}>
      <Text
        style={{
          color: "#000000",
          fontSize: "15px",
          marginBottom: "7px",
          fontWeight: 500,
          textDecoration: "underline",
        }}

      >
        SKILLS
      </Text>
      {skills.map((s) => (
        <Text
          style={{
            color: "#000000",
            fontSize: "14px",
            paddingBottom: "8px",
            paddingLeft: "8px",
          }}
        >
          {s.skill_name}
        </Text>
      ))}
    </View>
  );
};
*/}


const References = ({ data, dataKey, heading, fieldMap = {} }) => {
  const items = data[dataKey];
  const { title, subTitle, phoneNo, emailId } = fieldMap;
  if (!items || !items.length) {
    return <View />;
  }
  const{
    template_color_code,
  }=data;
  return (
    <View
      style={{
        marginLeft: "15px",
        color: "#000000",
        marginBottom: "15px",
      }}
    >
    <Svg
    xmlns="http://www.w3.org/2000/svg"
    height="30px"
    width="25px"
    viewBox="0 0 24 24"
    style={{
      position: "absolute",
      marginLeft: "-30px",
      marginTop: "-3px",
      
    }}
  >
    {/* Person */}
    <Circle cx="15" cy="12" r="8" style={{ stroke: "#000", strokeWidth: 1, fill:template_color_code, }} />
    <Path d="M12 13 L12 18" style={{ stroke: "#000", strokeWidth: 1 }} />
    <Path d="M9 15 L12 18 L15 15" style={{ stroke: "#000", strokeWidth: 1, fill: "none" }} />
    <Path d="M12 10 L12 13" style={{ stroke: "#000", strokeWidth: 1 }} />
    {/* Badge */}
    <Rect x="12" y="12" width="10" height="4" rx="1" ry="1" style={{ fill: "#000", stroke: "#000", strokeWidth: 1 }} />
  </Svg>
  

      <Text
        style={{
          fontSize: "15px",
          fontWeight: 700,
          letterSpacing: "2px",
          color:template_color_code,
        }}
      >
        {heading}
      </Text>
      {items.map((item) => (
        <View style={{ marginBottom: "15px" }}>
          <View
            style={{
              fontSize: "15px",
              fontWeight: "500",
              marginTop: "12px",
            }}
          >
            <Text>{item[title]}</Text>
          </View>
          <Text style={{ fontSize: "12px", marginTop: "4px", fontWeight: "bold" }}>
            {item[subTitle]}
          </Text>
          <Text
            style={{
              fontSize: "12px",
              color: "#000000",
              marginTop: "5px",
              fontWeight: 500,
            }}
          >
            {item[phoneNo]}
          </Text>
          <Text
            style={{
              fontSize: "11px",
              color: "#000000",
              marginTop: "2px",
              fontWeight: 500,
            }}
          >
            {item[emailId]}
          </Text>
        </View>
      ))}
    </View>
  );
};

const AdditionalInfo = ({
  data: {
    Address,
    City,
    Postal_Code,
    Driving_License,
    Nationality,
    dob,
    Place_of_Birth,
    //hobbies,
  },
}) => {
  const details = [
    {
      label: "Address",
      value: `${Address || ""} ${City || ""} ${Postal_Code || ""}`.trim(),
    },

    {
      label: "Driving License",
      value: Driving_License,
    },
    {
      label: "Nationality",
      value: Nationality,
    },
    {
      label: "Date of Birth",
      value: dob,
    },
    {
      label: "Place of Birth",
      value: Place_of_Birth,
    },
  ];
  return details.map((info) =>
    info.value ? (
      <View style={{ padding:"10px 150px",}}>
        <Text
          style={{
            color: "#ffffff",
            fontWeight: 600,
            fontSize: "16px",
            width:"250px",
          }}
        >
          {info.label}
        </Text>
        <Text
          style={{
            marginTop: "3px",
            color: "#ffffff",
            fontSize: "14px",
            //paddingLeft: "20px",
            padding:"5px 15px 0px 3px",
            width:"200px",
          }}
        >
          {info.value}
        </Text>
      </View>
    ) : (
      <View />
    )
  );
};

const Hobbies = ({ data: { hobbies } }) => {
  if (!hobbies || !hobbies.length) {
    return <View />;
  }
  return (
    <View style={{  padding:"10px 150px", marginTop: "5px"
     , }}>
      <Text
        style={{
          color: "#ffffff",
          fontSize: "14px",
          marginBottom: "5px",
          fontWeight: 500,
        
        }}
      >
        HOBBIES
      </Text>

      {hobbies.map((s) => (
        <Text
          style={{
            color: "#ffffff",
            fontSize: "14px",
            paddingBottom: "5px",
            paddingLeft: "4px",
            width:"175px",
          }}
        >
          {s.hobbies}
        </Text>
      ))}
    </View>
  );
};

const Skills = ({ data: { skills } }) => {
  if (!skills || !skills.length) {
    return <View />;
  }
  return (
    <View style={{ padding:"10px 150px", marginTop: "5px" }}>
      <Text
        style={{
          color: "#ffffff",
          fontSize: "14px",
          marginBottom: "5px",
          fontWeight: 500,
          //textDecoration: "underline",
        }}
      >
        SKILLS
      </Text>
      {skills.map((s) => (
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "5px",
          }}
        >
          <Text
            style={{
              color: "#ffffff",
              fontSize: "14px",
              //marginRight: "30px",
              //paddingBottom: "5px",
              padding:"10px 5px",
            }}
          >
            {s.skill_name}
          </Text>
          <View
            style={{
              backgroundColor: "#ffffff",
              width: "156px",
              height: "4px",
            }}
          >
            <View
              style={{
                backgroundColor: "blue",
                height: "4px",
                width: `${s.level * 52}px`,
              }}
            ></View>
          </View>
        </View>
      ))}
    </View>
  );
};

const Languages = ({ data: { languages } }) => {
  if (!languages || !languages.length) {
    return <View />;
  }
  return (
    <View style={{ padding: "10px 150px", }}>
      <Text
        style={{
          color: "#ffffff",
          fontSize: "16px",
          marginBottom: "6px",
          fontWeight: 600,
          padding:"5px 3px",
        }}
      >
        Languages
      </Text>
      {languages.map((s) => (
        <Text
          style={{
            color: "#ffffff",
            fontSize: "14px",
            paddingBottom: "5px",
            paddingLeft: "4px",
          }}
        >
          {s.language_name}
        </Text>
      ))}
    </View>
  );
};
const template9 = ({ data }) => {
  // console.log("inside template ", data);
  const {
    profileImage,
    First_Name,
    Last_Name,
    Wanted_Job_Title,
    template_color_code,
    professionalSummary: { Description = "" } = {},
  } = data;
  return (
    <TemplatePage data={data}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.wrapper}>
            <View style={styles.left}>

              {/*  <View style={styles.header}>
                </View><Text style={styles.name}>*/}
                  
              <View style={styles.profileImageWrapper}>
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
              </View>
              <View style={styles.header}>
                <Text style={{ 
                  fontFamily: "Roboto",
                paddingLeft: "120px",
                marginTop: "-115px",
                fontSize: "18px",
                color:" #43479e",
                fontWeight: 700,
                width:"250px",
              }}>

                <Text style={{color:template_color_code,fontSize:"18px"}}>{First_Name?First_Name:"YourName"}</Text>  
               
                </Text>
                <Text style={{color:"grey",fontWeight:600,left:"63%",}}>{Last_Name?Last_Name:""}</Text>
                   <Text style={styles.jobTitle}>{Wanted_Job_Title ? Wanted_Job_Title:"Job Title"}
                </Text>
                <ContactInfo data={data}/>
              </View>
              {/*<View>
                {data.professionalSummary.Description && (
                  <View style={{ marginTop: "-25px", }}>
                    <Text
                      style={{
                        lineHeight: "1.5px",
                        fontSize: "15px",
                        fontWeight: 500,
                        letterSpacing: "2px",
                        color: "#42489e",
                        textAlign:"justify",
                        textAlignLast:"right",
                        //paddingTop:"15px",
                      }}
                    >
                      About Me
                    </Text>
                    <View>
                      {renderHTMLContent(data.professionalSummary.Description, {
                        "*": {
                          color: "#000000",
                          fontWeight: 500,
                          fontSize: "11px",
                        
                        },
                      })}
                    </View>
                  </View>
                )}
              </View>
                    */}
                    
              <RenderView
                data={data}
                dataKey="employmentHistory"
                heading="Work Experience"
                fieldMap={{
                  title: "Employer",
                  subTitle: "Job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  city: "city",
                  description: "Description",
                }}
              />
              {console.log(data)}
              <RenderView
                data={data}
                dataKey="education"
                heading="EDUCATION"
                fieldMap={{
                  title: "institute_name",
                  subTitle: "degree",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "Description",
                  city: "city",
                }}
              />
              <RenderView
                data={data}
                dataKey="activities"
                heading="EXTRA_CURRICULAR_ACTIVITY"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "function_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  city: "city",
                  description: "description",
                }}
              />
              <RenderView
                data={data}
                dataKey="internships"
                heading="INTERNSHIPS"
                fieldMap={{
                  title: "employer_name",
                  subTitle: "job_title",
                  startDate: "start_date",
                  endDate: "end_date",
                  city: "city",
                  description: "description",
                }}
              />
              
              <RenderView
                data={data}
                dataKey="courses"
                heading="COURSES"
                fieldMap={{
                  title: "course_name",
                  subTitle: "institute_name",
                  startDate: "start_date",
                  endDate: "end_date",
                  city: "city",
                }}
              />
              <References
                data={data}
                dataKey="References"
                heading="References"
                fieldMap={{
                  title: "ref_name",
                  subTitle: "company_name",
                  phoneNo: "phone_no",
                  emailId: "ref_email",
                }}
              />
              <CustomSection
              data={data}
                dataKey="customSection"
                //heading="custom_title"
                fieldMap={{
                  custom_title:"custom_title",
                  title: "section_name",
                  city: "city",
                  startDate: "start_date",
                  endDate: "end_date",
                  description: "description",
                }}
              />
            </View>

            <View style={styles.right}>
              <View
                style={{
                  position: "absolute",
                  height: "100vh",
                  width: "300px",
                  left: "35%",
                  top: "-30px",
                  padding:"0px",
                  backgroundColor: template_color_code,
                }}
                fixed
              
                render={({ pageNumber }) => (
                  <View
                    style={{
                      height: "100vh",
                      backgroundColor:
                        pageNumber === 1 ? "transparent" : template_color_code,
                    }}
                  ></View>
                )}
              />

              <View>
                {data.professionalSummary.Description && (
                  <View style={{ marginTop: "-50px", padding: "10px", fontSize:"20px",}}>
                    <Text
                      style={{
                        lineHeight: "Normal",
                        fontSize: "15px",
                        fontWeight: 500,
                        letterSpacing: "1px",
                        color: "#ffffff",
                        marginLeft:"8px",
                        textAlign:"justify",
                        //paddingTop:"15px",
                      }}
                    >
                      
                    </Text>
                    <View style={{ marginTop: "45px", marginLeft: "16px" ,padding:"20px 100px ",}}>
                <Svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <Path
                    d="M0.826122 12.3301C0.359684 10.3032 0.126465 8.54401 0.126465 7.05253C0.126465 4.91091 0.631773 3.3047 1.64239 2.23389C2.65301 1.12484 4.13006 0.570312 6.07355 0.570312V2.8649C5.06294 2.8649 4.32441 3.13261 3.85797 3.66801C3.4304 4.20341 3.21662 5.00652 3.21662 6.07733H6.13186C6.13186 6.07733 7.99374 10.7502 6.13186 12.3301C4.55197 13.6707 0.826122 12.3301 0.826122 12.3301ZM8.69727 12.3301C8.23083 10.3032 7.99761 8.54401 7.99761 7.05253C7.99761 4.91091 8.50292 3.3047 9.51353 2.23389C10.5242 1.12484 12.0012 0.570312 13.9447 0.570312V2.8649C12.9341 2.8649 12.1956 3.13261 11.7291 3.66801C11.3015 4.20341 11.0878 5.00652 11.0878 6.07733H13.9447C13.9447 6.07733 15.8588 10.7429 14.003 12.3301C12.4283 13.6768 8.69727 12.3301 8.69727 12.3301Z"
                    fill="white"
                  />
                </Svg>
              </View>
                    <View>
                      {renderHTMLContent(data.professionalSummary.Description, {
                        "*": {
                          color: "#ffffff",
                          fontWeight: 400,
                          fontSize: "13px",
                          marginLeft:"15px",
                          paddingLeft:"50px",
                          textAlign:"justify",
                          marginTop:"-5px",
                        
                        },
                      })}
                    </View>
                  </View>
                )}
              </View>
              {/* <View style={styles.profileImageWrapper}>
                <Image
                  src={profileImage || DummyProfilePic}
                  style={styles.profileImage}
                />
                  </View>*/}
              {/*<ContactInfo data={data} />*/}
              <Skills data={data} />
              <Hobbies data ={data}/>
              <Languages data={data} />
              <AdditionalInfo data={data} />
            </View>
          </View>
        </Page>
      </Document>
    </TemplatePage>
  );
};

template9.displayName = "Template9";
export default template9;
