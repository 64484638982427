// import node module libraries
import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  Navbar,
  Nav,
  Container,
  Form,
  Button,
  Dropdown,
  ListGroup,
  OverlayTrigger,
  DropdownButton,
  Tooltip,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useMediaQuery } from "react-responsive";

// simple bar scrolling used for notification item scrolling
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// import custom components
import DotBadge from "components/elements/bootstrap/DotBadge";
import DocumentMenu from "./DocumentMenu";

// import media files
import Logo from "assets/images/brand/logo/logo.svg";
//import Logo from 'assets/images/svg/header_logo.88fca79c.svg';
import Avatar1 from "assets/images/avatar/avatar-1.jpg";

// import data files
import NavbarDefaultRoutes from "routes/marketing/NavbarDefault";
import EmployerRoutes from "components/employer/EmployerRoutes";
import CollegeRoutes from "components/college/CollegeStudentRoutes";
import NotificationList from "data/Notification";
import NavDropdownMain from "layouts/marketing/navbars/NavDropdownMain";

import "./NavbarDefault.css";
import Url from "../../../components/Url";
import LoginModalNew from "components/authentication/LoginModalNew";
import ResetPasswordModal from "components/authentication/ResetPasswordModal";

const NavbarDefault = ({ headerstyle, login }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  // const [studentId, setIsAutheticated] = useState(
  //   localStorage.getItem("studentId")
  // );
  // const [studentToken, setStudentToken] = useState(
  //   localStorage.getItem("stutoken")
  // );
  // const [collegeToken, setcollegeToken] = useState(
  //   localStorage.getItem("collegetoken")
  // );
  // const [employerToken, setEmployerToken] = useState(
  //   localStorage.getItem("emptoken")
  // );
  // const [studentName, setStudentName] = useState(
  //   localStorage.getItem("loginName")
  // );
  // const [studentEmail, setStudentEmail] = useState(
  //   localStorage.getItem("email")
  // );
  const studentId = localStorage.getItem("studentId");
  const studentToken = localStorage.getItem("stutoken");
  const collegeToken = localStorage.getItem("collegetoken");
  const employerToken = localStorage.getItem("emptoken");
  const studentName = localStorage.getItem("loginName");
  const studentEmail = localStorage.getItem("email");
  const mentorToken = localStorage.getItem("mentortoken");
  const mentorName = localStorage.getItem("mentorName");

  const [expandedMenu, setExpandedMenu] = useState(false);
  const url1 = Url();
  const url = url1["url"];
  const s3 = url1["s3"];
  const tempInternshipURL = url1["tempInternshipURL"];
  const s3url = s3 + "blogImages/";
  const [modalShow, setModalShow] = useState(false);
  const [pwdchnagemodal, setPwdchnagemodal] = useState(false);

  /*	
	useEffect(() => {

		const empToken = localStorage.getItem("emptoken");
		if(empToken){
		  const token1 = localStorage.getItem("token1");
		  const token2 = localStorage.getItem("token2");
		  if(token1 && token2) window.location.href = tempInternshipURL + "/" + token2 +"/" + token1 +"/" + empToken;  
		  else localStorage.clear();
		}
		
	
	  },[]);
	  */

  const QuickMenu = () => {
    return (
      <Fragment>
        <Dropdown
          as={Nav.Item}
          className={`${isDesktop || isLaptop ? "mt-2 me-0" : "mt-2 me-2"}`}
        >
          <Dropdown.Toggle
            as={Nav.Link}
            bsPrefix="dt"
            className="text-dark icon-notifications me-lg-1  btn btn-light btn-icon rounded-circle indicator indicator-primary text-muted"
            id="dropdownNotification"
          >
            <i className="fe fe-bell"></i>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-end mt-4 py-0"
            aria-labelledby="dropdownNotification"
            align="end"
          >
            <div className="border-bottom px-3 pt-3 pb-3 d-flex justify-content-between align-items-end">
              <span className="h4 mb-0">Notifications</span>
              <Link to="# " className="text-muted">
                <span className="align-middle">
                  <i className="fe fe-settings me-1"></i>
                </span>
              </Link>
            </div>
            <SimpleBar style={{ maxHeight: "300px" }}>
              <ListGroup variant="flush">
                {NotificationList.map(function (item, index) {
                  return (
                    <ListGroup.Item
                      className={index === 0 ? "bg-light" : ""}
                      key={index}
                    >
                      <Row>
                        <Col>
                          <Link className="text-body" to="#">
                            <div className="d-flex">
                              <Image
                                src={item.image}
                                alt=""
                                className="avatar-md rounded-circle"
                              />
                              <div className="ms-3">
                                <h5 className="fw-bold mb-1">{item.sender}</h5>
                                <p className="mb-3">{item.message}</p>
                                <span className="fs-6 text-muted">
                                  <span>
                                    <span className="fe fe-thumbs-up text-success me-1"></span>
                                    {item.date}
                                  </span>
                                  <span className="ms-1">{item.time}</span>
                                </span>
                              </div>
                            </div>
                          </Link>
                        </Col>
                        <Col className="col-auto text-center me-2">
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip-top">Mark as unread</Tooltip>
                            }
                          >
                            <Link to="#">
                              <DotBadge bg="secondary"></DotBadge>
                            </Link>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  );
                })}
              </ListGroup>
            </SimpleBar>
            <div className="border-top px-3 pt-3 pb-3">
              <Link
                to="/authentication/notifications"
                className="text-link fw-semi-bold"
              >
                See all Notifications
              </Link>
            </div>
          </Dropdown.Menu>
        </Dropdown>

        <Dropdown as={Nav.Item}>
          <Dropdown.Toggle
            as={Nav.Link}
            bsPrefix="dt"
            className="rounded-circle border-bottom-0"
            id="dropdownUser"
          >
            <div className="avatar avatar-md avatar-indicators avatar-online">
              <Image alt="avatar" src={Avatar1} className="rounded-circle" />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            show={isDesktop ? true : false}
            className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
            aria-labelledby="dropdownUser"
            align="end"
          >
            <Dropdown.Item className="mt-3">
              <div className="d-flex">
                <div className="avatar avatar-md avatar-indicators avatar-online">
                  <Image
                    alt="avatar"
                    src={Avatar1}
                    className="rounded-circle"
                  />
                </div>
                <div className="ms-3 lh-1">
                  <h5 className="mb-1">Annette Black</h5>
                  <p className="mb-0 text-muted">annette@geeksui.com</p>
                </div>
              </div>
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item eventKey="2">
              <i className="fe fe-user me-2"></i> Profile
            </Dropdown.Item>
            <Dropdown.Item eventKey="3">
              <i className="fe fe-star me-2"></i> Subscription
            </Dropdown.Item>
            <Dropdown.Item>
              <i className="fe fe-settings me-2"></i> Settings
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="mb-3">
              <i className="fe fe-power me-2"></i> Sign Out
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Fragment>
    );
  };

  const handleLinkClick = () => {
    // Close the Navbar when a specific link is clicked
    setExpandedMenu(!expandedMenu);
  };

  return (
    <Fragment>
      <Navbar
        onToggle={(collapsed) => setExpandedMenu(collapsed)}
        expanded={expandedMenu}
        expand="lg"
        className={`${login ? "bg-white" : ""} navbar p-2 ${
          headerstyle === "dark" ? "navbar-dark bg-dark" : "navbar-default py-2"
        }`}
      >
        {/*Internshipgate Customization - removed fluid class, only use container to have left right margins in the navbar <Container fluid className="px-0 ps-2"> */}
        <Container fluid className="px-0 mx-3 ps-2">
          <Navbar.Brand href="/">
            <Image src={Logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            {employerToken && !studentToken ? (
              <Nav>
                {EmployerRoutes.map((item, index) => {
                  if (item.children === undefined) {
                    return (
                      /*
										<Nav.Link key={index} as={Link} to={item.link}>
											{item.menuitem}
										</Nav.Link>
										*/

                      <Nav.Link key={index} href={item.link}>
                        {item.menuitem}
                      </Nav.Link>
                    );
                  } else {
                    return (
                      <NavDropdownMain
                        item={item}
                        key={index}
                        onClick={(value) => setExpandedMenu(value)}
                      />
                    );
                  }
                })}
              </Nav>
            ) : collegeToken ? (
              <Nav>
                {CollegeRoutes.map((item, index) => {
                  if (item.children === undefined) {
                    if (
                      item.collegeLogin === undefined ||
                      (collegeToken && item.collegeLogin)
                    ) {
                      return (
                        <Nav.Link key={index} as={Link} to={item.link}>
                          {item.menuitem}
                        </Nav.Link>
                      );
                    }
                  } else {
                    return (
                      <NavDropdownMain
                        item={item}
                        key={index}
                        onClick={(value) => setExpandedMenu(value)}
                      />
                    );
                  }
                })}
              </Nav>
            ) : (
              <Nav>
                {NavbarDefaultRoutes.map((item, index) => {
                  if (item.children === undefined) {
                    if (
                      item.studentLogin === undefined ||
                      (studentToken && item.studentLogin)
                    ) {
                      return (
                        <Nav.Link key={index} as={Link} to={item.link}>
                          {item.menuitem}
                        </Nav.Link>
                      );
                    }
                  } else {
                    return (
                      <NavDropdownMain
                        item={item}
                        key={index}
                        onClick={(value) => setExpandedMenu(value)}
                      />
                    );
                  }
                })}
              </Nav>
            )}

            {/* Search Form */}
            {/* 
						<Form className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
							<span className="position-absolute ps-3 search-icon">
								<i className="fe fe-search"></i>
							</span>
							<Form.Control
								type="Search"
								id="formSearch"
								className="ps-6"
								placeholder="Search Courses"
							/>
						</Form>
						*/}
            {/* Right side quick / shortcut menu  */}

            <div className="ms-lg-4 d-flex justify-content-between">
              {!employerToken && !studentToken && !collegeToken && !mentorToken ? (
                <span className={`ms-auto mt-3 mt-lg-0`}>
                  <div className="d-flex">
                    <Button variant="outline-primary"
                      onClick={() => setModalShow(true)}
                      className="shadow-sm me-2"
                    >
                      Login
                    </Button>
                    <div>
                      <DropdownButton
                        id="registration-buttons"
                        title="Register"
                      >
                        <Dropdown.Item as="div" onClick={handleLinkClick}>
                          <Link to="/student/registration">Student</Link>
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <Link
                            to="/employer/registration"
                            onClick={handleLinkClick}
                          >
                            Employer
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item as="div">
                          <Link
                            to="/college/registration"
                            onClick={handleLinkClick}
                          >
                            College
                          </Link>
                        </Dropdown.Item>
                      </DropdownButton>
                    </div>
                  </div>
                </span>
              ) : (
                <Dropdown className="ms-auto">
                  <Dropdown.Toggle
                    as={Nav.Link}
                    bsPrefix="dt"
                    className="rounded-circle border-bottom-0"
                    id="dropdownUser"
                  >
                    <div className="avatar avatar-md avatar-indicators avatar-online">
                      <Image
                        alt="avatar"
                        src={s3 + "Account.svg"}
                        className="rounded-circle"
                      />
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
                    aria-labelledby="dropdownUser"
                    align="end"
                  >
                    <Dropdown.Item className="mt-3">
                      <div className="d-flex">
                        <div className="avatar avatar-md avatar-indicators avatar-online">
                          <Image
                            alt="avatar"
                            src={s3 + "Account.svg"}
                            className="rounded-circle"
                          />
                        </div>
                        <div className="ms-3 lh-1">
                          <h5 className="mb-1">{studentName}</h5>
                          <p className="mb-0 text-muted">{studentEmail}</p>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    {studentToken ? (
                      <Dropdown.Item className="mb-3">
                        <Link to="/student-dashboard">
                          <i className="fe fe-user me-2"></i>Student Dashboard
                        </Link>
                      </Dropdown.Item>
                    ) : collegeToken ? (
                      <Dropdown.Item className="mb-3">
                        <Link to="/coldashboard">
                          <i className="fe fe-user me-2"></i>college Dashboard
                        </Link>
                      </Dropdown.Item>
                    ) : employerToken ? (
                      <Dropdown.Item className="mb-3">
                        <Link to="/empdashboard">
                          <i className="fe fe-user me-2"></i>Employer Dashboard
                        </Link>
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item className="mb-3">
                        <Link to="/mentor/dashboard">
                          <i className="fe fe-user me-2"></i>Mentor Dashboard
                        </Link>
                      </Dropdown.Item>
                    ) 
                    }
                    {/**
									
									<Dropdown.Item eventKey="2">
										<i className="fe fe-user me-2"></i> Profile
									</Dropdown.Item>
									<Dropdown.Item eventKey="3">
										<i className="fe fe-star me-2"></i> Subscription
									</Dropdown.Item>
									<Dropdown.Item>
										<i className="fe fe-settings me-2"></i> Settings
									</Dropdown.Item>
									*/}
                    <Dropdown.Divider />
                    <Dropdown.Item className="mb-3">
                      <div
                        className="text-primary"
                        onClick={() => {
                          setPwdchnagemodal(true);
                        }}
                      >
                        <i className="fe fe-user me-2"></i>
                        Reset Password
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className="mb-3">
                      <Link to="/logout">
                        <i className="fe fe-power me-2"></i> Sign Out
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </div>
            {/* 
							<span
								className={`${login
									? isDesktop || isLaptop
										? 'd-flex'
										: 'd-none'
									: 'd-none'
									}`}
							>
								<QuickMenu />
							</span>
						</Nav>
						*/}
            {/* end of right side quick / shortcut menu  */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <LoginModalNew
        show={modalShow}
        setModalShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
      <ResetPasswordModal
        show={pwdchnagemodal}
        setPwdchnagemodal={setPwdchnagemodal}
        onHide={() => setPwdchnagemodal(false)}
      ></ResetPasswordModal>
    </Fragment>
  );
};

// Specifies the default values for props
NavbarDefault.defaultProps = {
  headerstyle: "navbar-default",
  login: false,
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
  headerstyle: PropTypes.string,
  login: PropTypes.bool,
};

export default NavbarDefault;
